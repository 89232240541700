@font-face {
  font-family: 'ProductSans';
  src: url('../fonts/product-sans/Product Sans Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ProductSans';
  src: url('../fonts/product-sans/Product Sans Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

// Fonts
$font-family: 'ProductSans', 'Open Sans', Verdana, Tahoma;
$regular: 400;
$bold: 600;
