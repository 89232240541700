

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  font-weight: $regular;
  //font-size: 14px;
  //line-height: 1.4;
  @include fs-lh(14);
  color: $secondary-black;
  //display: flex;
}

//Set up headings
h1, h2, h3, h4, h5, h6 {
  color: $black;
}

h1 { font-size: 32px; }
h2 { font-size: 24px; }
h3 { font-size: 20px; }
h4 { font-size: 18px; }
h5 { font-size: 16px; }


.bold {
  font-weight: $bold;
}

$standard-margin: 15px;
