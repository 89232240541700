$pure-white: #fff;
$pure-black: #000;
$red: #ce2e35;
$primary: $red;
$black: #1e1e1e;
$white: #fff;
$secondary-black: #717171;
$secondary-red: #f25353; // Used for gradient for primary CTA
$dark-red: #bf3844; // used as background replacement for red background and item with opacity. Check sticky unit on payment page.
$background-gray: #f2f3f8;
$border-gray: #979797;
$gray200: #d6d9e8;
$white85: rgba(255, 255, 255, 0.85);
$white19: rgba(255, 255, 255, 0.19);
$white60: rgba(255, 255, 255, 0.6);
$black8: rgba(0, 0, 0, 0.08);
$black20: rgba(0, 0, 0, 0.2);
$black500: #282828;
$purple: #6972d1;
$red-bg: #ffe6e8;
$green-bg: #e1ffe7;
$green: #3eb557;
$stockLeftRed: #fa6400;
$red800: #f24d55;
$red600: #e56771;
$red400: #ffc5ca;
$red200: #ffe6e8;
$green800: #25885b;
$green600: #57af87;
$green400: #80cda1;
$green200: #e1ffe7;
$blue800: #3d8cc6;
$blue600: #42acd1;
$blue400: #83c9e1;
$blue200: #dcf3fb;
$yellow800: #f68a11;
$yellow600: #fba83f;
$yellow500: #ffc585;
$yellow400: #ffce5b;
$yellow300: #fff6d8;
$yellow200: #fff0bc;
$black1000: #bdc8d3;
$black800: #1e1e1e;
$black600: #717171;
$black400: #979797;
$black200: #f2f3f8;
$app-download-banner-one: #ffe792;
$app-download-banner-two: #ffce59;
$bestseller-tag-yellow-one: #ffe792;
$bestseller-tag-yellow-two: #ffce59;
$purple-main: #7d50a1;
$purple-secondary: #9f64ce;
$purple800: $purple-main;
$purple200: #f4e7ff;
$purple400: #ede0f9;
$purple600: #6b73d2;
$purple800: #735fc3;
$purple1000: #624eb3;
$walletButtonGradient: #e1e6e0;

//
//$black80: rgba(0, 0, 0, 0.8);
//$black56: rgba(0, 0, 0, 0.56);
//$black36: rgba(0, 0, 0, 0.36);
//$green: #91c11e;
//$blue-bg: #cce5ff;
//$red-bg: #f8d7da;
//$green-bg: #d4edda;
//$light-gray: #f8f8f8;
//$gray: #e6e6e6;
//$background-color: #f5f5f5;

// Messages colors
$error-bg: $red-bg;
$error-text: $red;

$success-bg: $green-bg;
$success-text: $green;

$presale-bg: $pure-white;
$presale-text: $black;

//
