.lottie-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999;
}
